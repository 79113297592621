import React from "react"
import { detectMob } from "@src/Helper"

const PrivacyPolicy = () => {
  return (
    <div id={`${detectMob() && "m-privacyPolicy"}`} className="privacy-policy">
      <div className="privacy-policy__jumbotron">
        <div className="container pad-t-175 pad-b-100">
          <h1 className="privacy-policy__jumbotron__title heading-1">
            Kebijakan Privasi
          </h1>
        </div>
      </div>
      <div className="container pad-t-40 pad-b-70 pad-x-40">
        <div className="privacy-policy__item">
          <p className="body">
            Kebijakan Privasi berikut ini menjelaskan bagaimana kami, Sociopipe
            (PT. Eksekutif Media Utama dan afiliasi-afiliasi, atau yang
            selanjutnya akan disebut dengan “kami”) mengumpulkan, menyimpan,
            menggunakan, mengolah, menguasai, mentransfer, mengungkapkan dan
            melindungi informasi Pribadi Anda. Kebijakan privasi ini berlaku
            bagi seluruh pengguna aplikasi, situs web (
            <a
              className="privacy-policy__link"
              href="https://www.sociopipe.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.sociopipe.com
            </a>{" "}
            dan www.pipeho.me), layanan atau produk kami kecuali diatur pada
            kebijakan privasi yang terpisah.
          </p>

          <p className="body">
            Kebijakan Privasi yang ditetapkan di bawah ini merujuk kepada Syarat
            dan Ketentuan yang telah Anda sepakati setelah Anda menyatakan
            setuju atas Syarat dan Ketentuan yang ditetapkan oleh kami pada saat
            Anda mulai menggunakan layanan kami.
          </p>

          <p className="body mb-2">
            Adapun kebijakan privasi ini mencakup hal-hal sebagai berikut:
          </p>

          <ol>
            <li>
              <p className="body mb-0">Informasi Pribadi yang kami kumpulkan</p>
            </li>
            <li>
              <p className="body mb-0">
                Penggunaan Informasi Pribadi yang kami kumpulkan
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Pemberian Informasi Pribadi yang kami kumpulkan
              </p>
            </li>
            <li>
              <p className="body mb-0">Penyimpanan Informasi Pribadi</p>
            </li>
            <li>
              <p className="body mb-0">Akses dan koreksi Informasi Pribadi</p>
            </li>
            <li>
              <p className="body mb-0">Keamanan Informasi Pribadi Anda</p>
            </li>
            <li>
              <p className="body mb-0">Perubahan atas Kebijakan Privasi ini</p>
            </li>
            <li>
              <p className="body mb-0">Pengakuan dan persetujuan</p>
            </li>
            <li>
              <p className="body mb-0">Materi pemasaran dan promosi</p>
            </li>
            <li>
              <p className="body mb-0">Data anonim</p>
            </li>
            <li>
              <p className="body mb-0">Platform pihak ketiga</p>
            </li>
            <li>
              <p className="body mb-0">Cara untuk menghubungi kami</p>
            </li>
          </ol>
        </div>
        <div className="privacy-policy__item">
          <h5 className="heading-5">
            1. Informasi Pribadi Yang Kami Kumpulkan
          </h5>
          <p className="body">
            Kami mengumpulkan informasi yang mengidentifikasikan atau dapat
            digunakan untuk mengidentifikasi, menghubungi, atau menemukan orang
            atau perangkat yang terkait dengan informasi tersebut.{" "}
            <span className="text-700">Informasi Pribadi</span> termasuk, tetapi
            tidak terbatas pada, nama, email, username, kata sandi ter-hash,
            lokasi yang meliputi kota dan negara, foto profil. Sebagai data
            tambahan guna menyediakan analitik yang lebih baik, kami juga
            menyimpan dan menggunakan data pengunjung berupa lokasi yang
            meliputi kota dan negara, alamat IP, informasi peramban yang
            dikategorikan sebagai Informasi Pribadi.
          </p>
          <p className="subhead text-700">
            Informasi tambahan yang kami kumpulkan dari Anda sebagai penyedia
            layanan
          </p>
          <p className="body">
            Ketika Anda menggunakan situs web kami, Anda harus memberikan kami
            informasi yang relevan yang mungkin diperlukan oleh kami agar situs
            web kami dapat berfungsi semestinya, contohnya, informasi terkait
            dengan layanan Anda yang berinteraksi dengan pengunjung. Anda
            mungkin akan perlu memberikan kepada kami data lain yang kami
            perlukan untuk mengelola situs web kami dan ekosistem terkait, serta
            untuk memantau keseluruhan penggunaan situs web kami.
          </p>
          <p className="subhead text-700">
            Informasi yang dikumpulkan setiap kali Anda mengunjungi situs web
            kami
          </p>
          <ul>
            <li>
              <p className="body mb-0">
                Setiap Anda mengunjungi situs web kami, kami dapat mengumpulkan
                data teknis tertentu sehubungan dengan penggunaan Anda seperti
                alamat protokol internet, informasi halaman web yang sebelumnya
                atau selanjutnya dilihat, durasi setiap kunjungan/sesi,
                identitas perangkat internet atau alamat kontrol akses media,
                dan <em>mobile advertising</em> ID dan informasi perangkat
                lainnya termasuk informasi mengenai produsen, model, dan sistem
                operasi dari perangkat yang Anda gunakan untuk mengakses situs
                web kami.
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Ketika Anda mengunjungi situs web kami, informasi tertentu juga
                dapat dikumpulkan secara otomatis dengan menggunakan{" "}
                <em>cookies</em>. <em>Cookies</em> adalah berkas data kecil yang
                tersimpan pada komputer atau perangkat seluler Anda. Kami
                menggunakan <em>cookies</em> untuk melacak aktivitas pengguna
                dengan tujuan untuk meningkatkan antarmuka dan pengalaman
                pengguna. Sebagian besar perangkat seluler dan peramban internet
                mendukung penggunaan <em>cookies</em>; namun Anda dapat
                menyesuaikan pengaturan pada perangkat seluler atau peramban
                internet Anda untuk menolak beberapa jenis <em>cookies</em>{" "}
                spesifik. Perangkat seluler dan/atau peramban Anda juga
                memungkinkan Anda untuk menghapus <em>cookies</em> apapun yang
                sebelumnya telah tersimpan. Namun demikian, hal itu dapat
                mempengaruhi fungsi-fungsi yang tersedia pada situs web kami.
              </p>
            </li>
          </ul>
          <p className="subhead text-700 mt-4">
            Informasi tambahan yang kami kumpulkan dari Anda sebagai penyedia
            layanan
          </p>
          <p className="body">
            Ketika Anda menggunakan situs web kami, Anda harus memberikan kami
            informasi yang relevan yang mungkin diperlukan oleh kami agar situs
            web kami dapat berfungsi semestinya, contohnya, informasi terkait
            dengan layanan Anda yang berinteraksi dengan pengunjung. Anda
            mungkin akan perlu memberikan kepada kami data lain yang kami
            perlukan untuk mengelola situs web kami dan ekosistem terkait, serta
            untuk memantau keseluruhan penggunaan situs web kami.
          </p>
          <p className="subhead text-700">
            Informasi yang dikumpulkan dari pihak ketiga
          </p>
          <p className="body">
            Kami juga dapat mengumpulkan Informasi Pribadi Anda dari pihak
            ketiga (termasuk agen, vendor, pemasok, kontraktor, mitra, dan pihak
            lainnya yang memberikan layanan kepada kami, mengumpulkan Data
            Pribadi dan/atau melakukan tugas atas nama kami, atau dengan siapa
            kami melakukan kerja sama). Dalam kasus tersebut, kami hanya akan
            mengumpulkan Informasi Pribadi Anda untuk atau sehubungan dengan
            tujuan yang melibatkan pihak ketiga tersebut atau tujuan kerja sama
            kami dengan pihak ketiga tersebut (tergantung situasi).
          </p>
          <p className="subhead text-700">
            Informasi tentang pihak ketiga yang Anda berikan kepada kami
          </p>
          <p className="body">
            Anda dapat memberikan kepada kami Informasi Pribadi yang berkaitan
            dengan individu pihak ketiga lainnya (termasuk Informasi Pribadi
            yang berkaitan dengan pasangan Anda, anggota keluarga, teman, atau
            individu lain). Anda tentu saja akan memerlukan persetujuan dari
            mereka terlebih dahulu untuk melakukannya.
          </p>
        </div>
        <div className="privacy-policy__item">
          <h5 className="heading-5">
            2. Penggunaan Informasi Pribadi Yang Kami Kumpulkan
          </h5>
          <p className="body my-0">
            Kami dapat menggunakan Informasi Pribadi yang dikumpulkan untuk
            tujuan sebagai berikut maupun untuk tujuan lain yang diizinkan oleh
            peraturan perundang-undangan yang berlaku:
          </p>
          <ul>
            <li>
              <p className="body mb-0">
                Untuk memfasilitasi atau memungkinkan verifikasi apapun yang
                menurut pertimbangan kami diperlukan sebelum penyedia layanan
                memberikan layanan kepada Anda atau sebelum kami mendaftarkan
                Anda sebagai pengguna;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk berkomunikasi dengan Anda dan mengirimkan Anda informasi
                sehubungan dengan penggunaan situs web kami;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk memberitahu Anda mengenai segala pembaruan pada situs web
                kami;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk mengolah dan menanggapi pertanyaan dan saran yang diterima
                dari Anda;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk memelihara, mengembangkan, menguji, meningkatkan, dan
                mempersonalisasikan situs web kami untuk memenuhi kebutuhan dan
                preferensi Anda sebagai pengguna;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk memantau dan menganalisis aktivitas, perilaku dan data
                demografis pengguna termasuk kebiasaan dan penggunaan situs web;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk mengirimkan Anda komunikasi pemasaran, iklan, voucher,
                survei, dan informasi secara langsung atau terfokus, dan
                informasi tentang penawaran atau promosi khusus;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Jika Anda adalah penyedia layanan maka Informasi Pribadi
                memungkinkan Anda memberikan layanan kepada pengguna;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk menawarkan atau menyediakan layanan dari afiliasi atau
                mitra kami.
              </p>
            </li>
          </ul>
          <p className="body mt-3 mb-0">
            Kami juga dapat menggunakan Informasi Pribadi Anda secara lebih umum
            untuk tujuan-tujuan sebagai berikut:
          </p>
          <ul>
            <li>
              <p className="body mb-0">
                Untuk melakukan proses dan fungsi bisnis terkait;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk memantau penggunaan situs web dan mengelola, mendukung
                serta meningkatkan efisiensi kinerja, perkembangan, pengalaman
                pengguna dan fungsi-fungsi situs web kami.
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk memberikan bantuan sehubungan dengan dan untuk
                menyelesaikan kesulitan teknis atau masalah operasional dengan
                situs web kamu atau layanan;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk menghasilkan informasi statistik dan data analitik untuk
                tujuan pengujian, penelitian, analisis, pengembangan produk,
                kemitraan komersial, dan kerja sama;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk mencegah, mendeteksi dan menyelidiki segala kegiatan yang
                dilarang, ilegal, tidak sah, atau curang;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk memfasilitasi transaksi aset bisnis (yang dapat berupa
                penggabungan, akuisisi, atau penjualan aset) yang melibatkan
                kami dan/atau afiliasi kami; dan
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Untuk memungkinkan kami mematuhi semua kewajiban berdasarkan
                peraturan perundang-undangan yang berlaku, termasuk namun tidak
                terbatas pada menanggapi permintaan, penyelidikan, atau arahan
                peraturan, mematuhi persyaratan pengarsipan, pelaporan, dan
                perizinan yang disyaratkan oleh peraturan yang berlaku dan untuk
                melakukan pemeriksaan audit, uji tuntas dan penyelidikan.
              </p>
            </li>
          </ul>
        </div>
        <div className="privacy-policy__item mt-4">
          <h5 className="heading-5">
            3. Pemberian Informasi Pribadi Yang Kami Kumpulkan
          </h5>
          <ul>
            <li>
              <p className="body mb-0">
                Kami dapat mengungkapkan atau membagikan Informasi Pribadi Anda
                dengan afiliasi dan pihak lain untuk tujuan sebagai berikut ini
                serta untuk tujuan sebagai berikut ini serta untuk tujuan lain
                yang diizinkan oleh peraturan perundang-undangan yang berlaku:
              </p>
              <ol className="m-0 ps-3">
                <li>
                  <p className="body mb-0">
                    Jika Anda adalah pengguna, untuk memungkinkan penyedia
                    layanan, untuk melakukan atau memberikan layanan;
                  </p>
                </li>
                <li>
                  <p className="body mb-0">
                    Jika Anda adalah penyedia layanan, untuk memungkinkan
                    pengguna untuk meminta atau menerima layanan dari Anda;
                  </p>
                </li>
                <li>
                  <p className="body mb-0">
                    Jika disyaratkan atau diotorisasikan oleh peraturan
                    perundang-undangan yang berlaku (termasuk namun tidak
                    terbatas pada menanggapi pertanyaan terkait regulasi,
                    penyelidikan atau pedoman, atau mematuhi persyaratan atau
                    ketentuan pengarsipan dan pelaporan berdasarkan
                    undang-undang), untuk tujuan yang ditentukan dalam peraturan
                    perundang-undangan yang berlaku;
                  </p>
                </li>
                <li>
                  <p className="body mb-0">
                    Jika diinstruksikan, diminta, disyaratkan atau diperbolehkan
                    oleh pemerintah yang berwenang untuk tujuan sebagaimana
                    disebutkan dalam kebijakan pemerintah, peraturan atau
                    peraturan perundang-undangan yang berlaku;
                  </p>
                </li>
                <li>
                  <p className="body mb-0">
                    Jika terdapat proses hukum dalam bentuk apapun antara Anda
                    dengan kami, atau antara Anda dengan pihak lain, sehubungan
                    dengan, atau terkait dengan layanan, untuk keperluan proses
                    hukum tersebut;
                  </p>
                </li>
                <li>
                  <p className="body mb-0">
                    Sehubungan dengan segala proses verifikasi kami atau pihak
                    ketiga perlukan sebelum pemberi layanan memberikan layanan
                    kepada Anda atau kami mendaftarkan Anda sebagai pengguna;
                  </p>
                </li>
                <li>
                  <p className="body mb-0">
                    Dalam situasi terkait kepentingan umum, kamui dapat
                    membagikan Informasi Pribadi Anda kepada pemerintah yang
                    berwenang dan/atau institusi lainnya yang dapat ditunjuk
                    oleh pemerintah yang berwenang atau memiliki kerja sama
                    dengan kami, untuk tujuan pelacakan kontak, mendukung
                    inisiatif, kebijakan atau program pemerintah, kesehatan
                    public dan tujuan lainnya sebagaimana dibutuhkan secara
                    wajar;
                  </p>
                </li>
                <li>
                  <p className="body mb-0">
                    Sehubungan dengan, penggabungan, penjualan aset perusahaan,
                    konsolidasi atau restrukturisasi, pembiayaan atau akuisisi
                    semua atau sebagian dari bisnis kami oleh atau ke perusahaan
                    lain, untuk keperluan transaksi tersebut (bahkan jika
                    kemudian transaksi tidak dilanjutkan);
                  </p>
                </li>
                <li>
                  <p className="body mb-0">
                    Sehubungan dengan proses klaim asuransi, kami akan
                    membagikan Informasi Pribadi Anda untuk tujuan pemrosesan
                    klaim asuransi kepada perusahaan asuransi yang bekerja sama
                    atau berkolaborasi dengan kami;
                  </p>
                </li>
                <li>
                  <p className="body mb-0">
                    Kepada pihak ketiga (termasuk agen, vendor, pemasok,
                    kontraktor, mitra, dan pihak lain yang memberikan layanan
                    kepada kamu atau Anda, melakukan tugas atas nama kami, atau
                    pihak dengan siapa kami mengadakan kerja sama komersial),
                    untuk atau sehubungan dengan tujuan di mana pihak ketiga
                    tersebut terlibat, untuk melaksanakan pengungkapan kepada
                    pihak ketiga terkait yang secara teknis diperlukan untuk
                    memproses transaksi Anda atau tujuan kerja sama kami dengan
                    pihak ketiga tersebut, yang dapat mencakup diperbolehkannya
                    pihak tersebut untuk memperkenalkan atau menawarkan produk
                    atau layanan kepada Anda, melakukan autentikasi terhadap
                    Anda atau menghubungkan Anda dengan akun atau melakukan
                    kegiatan lain termasuk pemasaran, penelitian, analisis dan
                    pengembangan produk.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p className="body mb-0">
                Kami tidak akan menjual atau menyewakan Informasi Pribadi Anda.
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Selain sebagaimana diatur dalam Kebijakan Privasi ini, kami
                dapat mengungkapkan dan membagikan Informasi Pribadi Anda jika
                kami memberitahu Anda dan kami telah memperoleh persetujuan Anda
                untuk pengungkapan atau pembagian tersebut.
              </p>
            </li>
          </ul>
        </div>
        <div className="privacy-policy__item mt-4">
          <h5 className="heading-5">4. Penyimpanan Informasi Pribadi</h5>
          <ul>
            <li>
              <p className="body mb-0">
                Informasi Pribadi Anda hanya akan disimpan selama diperlukan
                untuk memenuhi tujuan dari pengumpulannya, atau selama
                penyimpanan tersebut diperlukan atau diperbolehkan oleh
                peraturan perundang-undangan yang berlaku. Kami akan berhenti
                menyimpan Informasi Pribadi, atau menghapus maksud dari
                dikaitkannya Informasi Pribadi tersebut dengan Anda sebagai
                individu, segera setelah dianggap bahwa tujuan pengumpulan
                Informasi Pribadi tersebut tidak lagi dibutuhkan dengan
                menyimpan Informasi Pribadi dan penyimpanan tidak lagi
                diperlukan untuk tujuan bisnis atau secara hukum.
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa
                informasi Pribadi Anda disimpan oleh pihak lain dengan termasuk
                institusi pemerintah dengan cara tertentu. Dalam hal kami
                membagikan Informasi Pribadi Anda kepada institusi pemerintah
                yang berwenang dan/atau institusi lainnya yang dapat ditunjuk
                oleh pemerintah yang berwenang atau memiliki kerja sama dengan
                kami, Anda menyetujui dan mengakui bahwa penyimpanan Informasi
                Pribadi Anda oleh institusi terkait akan mengikuti kebijakan
                penyimpanan data masing-masing institusi tersebut. Informasi
                yang disampaikan melalui komunikasi antara pengguna dan penyedia
                layanan yang dilakukan selain melalui penggunaan situs web kami
                (seperti melalui panggilan telepon, SMS, pesan seluler atau cara
                komunikasi lain dan pengumpulan atas Informasi Pribadi Anda oleh
                agen kami) juga dapat disimpan dengan beberapa cara. Kami tidak
                mengizinkan penyimpanan Informasi Pribadi dengan cara demikian
                dan kami tidak bertanggung jawab kepada Anda untuk hal tersebut.
                Kami tidak akan bertanggung jawab atas penyimpanan Informasi
                Pribadi Anda. Anda setuju untuk mengganti rugi , mebela, dan
                membebaskan kami, pejabat, direktur, karyawan, agen, mitra,
                pemasok, kontraktor, dan afiliasi kami dari dan terhadap setiap
                dan segala klaim, kerugian, kewajiban, biaya, kerusakan, dan
                ongkos (termasuk tetapi tidak terbatas pada biaya hukum dan
                pengeluaran biaya ganti rugi penuh) yang dihasilkan secara
                langsung atau tidak langsung dari setiap penyimpanan yang tidak
                sah atas Informasi Pribadi Anda.
              </p>
            </li>
          </ul>
        </div>
        <div className="privacy-policy__item mt-4">
          <h5 className="heading-5">5. Akses dan Koreksi Informasi Pribadi</h5>
          <ul>
            <li>
              <p className="body mb-0">
                Tunduk pada peraturan perundang-undangan yang berlaku, Anda
                dapat meminta kepada kami untuk mengakses dan/atau mengoreksi
                Informasi Pribadi Anda yang berada dalam kepemilikan dan
                penguasaan kami, dengan menghubungi kami di perincian yang
                disediakan di bawah ini.
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Kami berhak menolak permintaan Anda untuk mengakses, atau untuk
                memperbaiki, sebagian atau semua Informasi Pribadi Anda yang
                kami miliki atau kuasai jika diizinkan atau diperlukan
                berdasarkan perundang-undangan yang berlaku. Hal ini termasuk
                dalam keadaan dimana Informasi Pribadi tersebut dapat berisi
                referensi kepada orang lain atau dimana permintaan untuk akses
                atau permintaan untuk mengoreksi adalah untuk alasan yang kami
                anggap tidak relevan, tidak serius, atau menyulitkan.
              </p>
            </li>
          </ul>
        </div>
        <div className="privacy-policy__item mt-4">
          <h5 className="heading-5">6. Keamanan Informasi Pribadi Anda</h5>
          <p className="body">
            Kerahasiaan Informasi Pribadi Anda adalah hal yang terpenting bagi
            kami. Kami akan memberlakukan upaya terbaik untuk melindungi dan
            mengamankan data dan Informasi Pribadi Anda dari akses, pengumpulan,
            penggunaan atau pengungkapan oleh orang-orang yang tidak berwenang
            dan dari pengolahan yang bertentangan dengan hukum, kehilangan yang
            tidak disengaja, pemusnahan dan kerusakan atau risiko serupa. Namun
            pengiriman informasi melalui internet tidak sepenuhnya aman. Walau
            kami akan berusaha sebaik mungkin untuk melindungi Informasi Pribadi
            Anda, Anda mengakui bahwa kami tidak dapat menjamin keutuhan dan
            keakuratan Informasi Pribadi apapun yang Anda kirimkan melalui
            internet, atau menjamin bahwa Informasi Pribadi tersebut tidak akan
            dicegat, diakses, diungkapkan, diubah atau dihancurkan oleh pihak
            ketiga yang tidak berwenang, karena faktor-faktor di luar kendali
            kami. Anda bertanggung jawab untuk menjaga kerahasiaan detail akun
            Anda, termasuk kata sandi Anda dengan siapapun dan harus selalu
            menjaga dan bertanggung jawab atas keamanan perangkat yang Anda
            gunakan.
          </p>
        </div>
        <div className="privacy-policy__item mt-4">
          <h5 className="heading-5">7. Perubahan Atas Kebijakan Privasi Ini</h5>
          <p className="body">
            Kami dapat meninjau dan mengubah Kebijakan Privasi ini atas
            kebijakan kami sendiri dari waktu ke waktu, untuk memastikan bahwa
            Kebijakan Privasi ini konsisten dengan perkembangan kami di masa
            depan, dan/atau perubahan persyaratan hukum atau peraturan. Kami
            tidak memiliki kewajiban untuk selalu memberitahu kepada Anda
            tentang perubahan tersebut, meskipun pada umumnya kami akan
            memberitahu Anda tentang perubahan tersebut melalui pemberitahuan
            umum yang dipublikasikan pada situs web atau sebaliknya ke alamat
            e-mail Anda yang tercantum dalam akun Anda. Anda setuju bahwa Anda
            bertanggung jawab untuk meninjau Kebijakan Privasi ini secara
            teratur untuk informasi terbaru tentang pengolahan data dan praktik
            perlindungan data kami, dan bahwa dengan Anda terus menggunakan
            situs web kami, berkomunikasi dengan kami, atau mengakses dan
            menggunakan layanan setelah adanya perubahan apapun terhadap
            Kebijakan Privasi ini akan dianggap sebagai persetujuan Anda
            terhadap Kebijakan Privasi ini dan segala perubahannya.
          </p>
        </div>
        <div className="privacy-policy__item mt-4">
          <h5 className="heading-5">8. Pengakuan dan Persetujuan</h5>
          <ul>
            <li>
              <p className="body mb">
                Dengan menyetujui Kebijakan Privasi, Anda mengakui bahwa Anda
                telah membaca dan memahami Kebijakan Privasi ini dan menyetujui
                segala ketentuannya. Secara khusus, Anda setuju dan memberikan
                persetujuan kepada kami untuk mengumpulkan, menggunakan,
                membagikan, mengungkapkan, menyimpan, mentransfer, atau mengolah
                Informasi Pribadi Anda sesuai dengan Kebijakan Privasi ini.
              </p>
            </li>
            <li>
              <p className="body mb">
                Dalam keadaan dimana Anda memberikan kepada kami Informasi
                Pribadi yang berkaitan dengan individu lain (seperti Informasi
                Pribadi yang berkaitan dengan pasangan Anda, anggota keluarga,
                teman, atau pihak lain), Anda menyatakan dan menjamin bahwa Anda
                telah memperoleh persetujuan dari individu tersebut untuk, dan
                dengan ini menyetujui atas nama individu tersebut untuk,
                pengumpulan, penggunaan, pengungkapan dan pengolahan Informasi
                Pribadi mereka oleh kami.
              </p>
            </li>
            <li>
              <p className="body mb">
                Anda dapat menarik persetujuan Anda untuk setiap atau segala
                pengumpulan, penggunaan atau pengungkapan Informasi Pribadi Anda
                kapan saja dengan memberikan kepada kami pemberitahuan yang
                wajar secara tertulis menggunakan rincian kontak yang disebutkan
                di bawah ini. Tergantung pada keadaan dan sifat persetujuan yang
                Anda tarik, Anda harus memahami dan mengakui bahwa setelah
                penarikan persetujuan tersebut, Anda mungkin tidak lagi dapat
                menggunakan situs web atau layanan kami. Penarikan persetujuan
                Anda dapat mengakibatkan penghentian akun Anda atau hubungan
                kontraktual Anda dengan kami, dengan semua hak dan kewajiban
                yang muncul sepenuhnya harus dipenuhi. Setelah menerima
                pemberitahuan untuk menarik persetujuan untuk pengumpulan,
                penggunaan atau pengungkapan Informasi Pribadi Anda, kami akan
                menginformasikan Anda tentang konsekuensi yang mungkin terjadi
                dari penarikan tersebut sehingga Anda dapat memutuskan apakah
                Anda tetap ingin menarik persetujuan.
              </p>
            </li>
          </ul>
        </div>
        <div className="privacy-policy__item mt-4">
          <h5 className="heading-5">9. Materi Pemasaran dan Promosi</h5>
          <p className="body">
            Kami dan mitra kami dapat mengirimkan Anda pemasaran langsung,
            iklan, dan komunikasi promosi melalui aplikasi push-notification,
            pesan melalui Aplikasi, pos, panggilan telepon, layanan pesan
            singkat (SMS), Platform Chat, Media Sosial, dan email (“Materi
            Pemasaran”) jika Anda telah setuju untuk berlangganan milis kami,
            dan/atau setuju untuk menerima materi pemasaran dan promosi dari
            kami.
          </p>
        </div>
        <div className="privacy-policy__item mt-4">
          <h5 className="heading-5">10. Data Anonim</h5>
          <p className="body">
            Kami dapat membuat, menggunakan, melisensikan atau mengungkapkan
            Informasi Pribadi, yang tersedia, dengan catatan, (i) bahwa semua
            hal yang dapat mengidentifikasi telah dihapus sehingga data, baik
            sendiri atau dikombinasi dengan data lain yang tersedia, tidak dapat
            dihubungkan dengan atau dikaitkan dengan atau tidak dapat
            mengidentifikasi suatu individu, dan (ii) data serupa telah
            digabungkan sehingga data asli membentuk bagian dari kumpulan data
            yang lebih besar.
          </p>
        </div>
        <div className="privacy-policy__item mt-4">
          <h5 className="heading-5">11. Platform Pihak Ketiga</h5>
          <p className="body">
            Situs web, dan Materi Pemasaran dapat berisi tautan ke situs web
            yang dioperasikan oleh pihak ketiga. Kami tidak mengendalikan atau
            menerima pertanggungjawaban atau tanggung jawab untuk situs web ini
            dan untuk pengumpulan, penggunaan, pemeliharaan, berbagi, atau
            pengungkapan data dan informasi oleh pihak ketiga tersebut. Silahkan
            mengacu pada syarat dan ketentuan dan kebijakan privasi dari situs
            web pihak ketiga tersebut untuk mengetahui bagaimana mereka
            mengumpulkan dan menggunakan Informasi Pribadi Anda.
          </p>
        </div>
        <div className="privacy-policy__item mt-4">
          <h5 className="heading-5">12. Cara Menghubungi Kami</h5>
          <p className="body">
            Jika Anda memiliki pertanyaan, silahkan hubungi{" "}
            <a
              href="mailto:support@sociopipe.com"
              className="privacy-policy__link"
            >
              support@sociopipe.com
            </a>
          </p>
          <p className="body">
            Untuk koreksi atau pembaharuan terhadap Informasi Pribadi Anda,
            harap menghubungi{" "}
            <a
              href="mailto:support@sociopipe.com"
              className="privacy-policy__link"
            >
              support@sociopipe.com
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
