import * as React from "react"
import PrivacyPolicy from "@components/PrivacyPolicy/PrivacyPolicy"
import Seo from "@components/seo"

const PrivacyPolicyPage = () => (
  <>
    <Seo title="Privacy Policy" />
    <PrivacyPolicy />
  </>
)

export default PrivacyPolicyPage
